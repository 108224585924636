<template>
  <div class="news-page">
    <!-- <top-box :option="3"></top-box> -->
    <div class="bannar">
      <!-- <img src="../../image/bg.png"> -->
      <!-- <img v-if="bannerSrc" :src="bannerSrc">
            <img v-else src="../../image/bg.png"> -->
      <img :src="bannerSrc" v-if="bannerType" />
      <video
        v-else
        muted="muted"
        id="startvideo"
        class="video"
        :src="bannerSrc"
        type="video/mp4"
        autoplay="autoplay"
        controls="controls"
        loop="-1"
      >
        <p>你的浏览器不支持video标签.</p>
      </video>
      <div class="overlay"></div>
      <div class="bannar-info">
        <div class="bannar-title">新闻资讯</div>
        <div class="bannar-detail">{{ bannerTitle }}</div>
      </div>
    </div>
    <main>
      <div class="main-option">
        <div
          @click="optionClick(index)"
          v-for="(item, index) in optionList"
          :key="index"
          :class="
            optionIdx == index ? 'main-option-item active' : 'main-option-item'
          "
        >
          {{ item.type }}
        </div>
      </div>
      <div v-if="type == ''" class="main-vedios">
        <a
          :href="item.link"
          v-for="(item, index) in newsList"
          target="blank"
          :key="index"
          class="main-vedios-item"
        >
          <div class="vedios-img">
            <img :src="item.src" :title="item.name" />
          </div>
          <div class="vedios-info">
            <div class="title">{{ item.name }}</div>
            <div class="time">{{ item.time }}</div>
          </div>
        </a>
        <div class="empty" v-if="newsList.length == 0">
          <div class="empty-bg"></div>
          <div class="empty-content">暂无数据~</div>
        </div>
      </div>
      <div v-else class="main-content">
        <div
          v-for="(item, index) in newsList"
          :key="index"
          class="main-content-item"
          @click="detailsClick(item.id)"
        >
          <div class="main-content-item-img">
            <img :src="item.src" :title="item.title" />
          </div>
          <div class="info">
            <div class="title">{{ item.title }}</div>
            <div class="content">
              {{ item.detail || "暂无介绍" }}
            </div>
            <div class="time">{{ item.releaseTime }}</div>
          </div>
        </div>
        <div class="empty" v-if="newsList.length == 0">
          <div class="empty-bg"></div>
          <div class="empty-content">暂无数据~</div>
        </div>
      </div>
      <div class="pagination" v-if="newsList.length > 0">
        <el-pagination
          background
          :page-size="4"
          layout="prev, pager, next"
          @current-change="currentChange"
          :total="total"
        >
        </el-pagination>
      </div>
    </main>
    <!-- <bottom-box></bottom-box> -->
  </div>
</template>
<script>
import {
  getBanner,
  getNew,
  getCategory,
  getVideos,
} from "../../../api/homeApi";
export default {
  data() {
    return {
      optionIdx: 0,
      optionList: ["最新发布", "公司新闻", "行业动态"],
      bannerSrc: "",
      bannerType: 1, //1.图片 0.视频
      bannerTitle: "",
      newsList: [],
      total: 0,
      pageNum: 1,
      type: 0,
    };
  },
  created() {
    this.getBanner();
    this.getCategory();
  },
  methods: {
    optionClick(val) {
      this.optionIdx = val;
      this.type = this.optionList[val].id;
      this.pageNum = 1;
      if (this.type == "") {
        this.getVideos();
      } else {
        this.getNew();
      }
      // this.getNew()
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      );
      return flag;
    },
    getBanner() {
      getBanner({
        plate: 3,
      }).then((res) => {
        if (res.code == 0) {
          if (res.infos.length > 0) {
            if (this._isMobile()) {
              this.bannerType = 1;
              this.bannerSrc = this.formatImageUrl(
                JSON.parse(res.infos[0].mobileImage)[0].raw
              );
            } else {
              let arr = JSON.parse(res.infos[0].image);
              if (
                [
                  "image/png", // .png
                  "image/jpeg", // .jpg .jpeg
                  "image/x-ms-bmp", // .bmp
                  "image/gif", // .gif
                  "image/tiff", // .tif .tiff
                ].includes(arr[0].type)
              ) {
                this.bannerType = 1;
              } else {
                this.bannerType = 0;
              }
              this.bannerSrc = this.formatImageUrl(
                JSON.parse(res.infos[0].image)[0].raw
              );
            }
            this.bannerTitle = res.infos[0].content;
          }
        }
      });
    },
    // 获取视频
    getVideos() {
      getVideos({
        limit: 4,
        page: this.pageNum,
      }).then((res) => {
        if (res.code == 0) {
          this.total = res.page.totalCount;
          this.newsList = res.page.list.map((item) => {
            item.src = "";
            if (item.image) {
              item.image = JSON.parse(item.image);
              if (item.image.length > 0) {
                item.src = this.formatImageUrl(item.image[0].raw);
              }
            }
            if (item.link) {
              if (!item.link.includes("http")) {
                item.link = "http://" + item.link;
              }
            }
            item.time = item.time.split(" ")[0];
            return item;
          });
        }
      });
    },
    // 获取咨询
    getNew() {
      getNew({
        limit: 4,
        page: this.pageNum,
        type: this.type,
      }).then((res) => {
        if (res.code == 0) {
          this.total = res.page.totalCount;
          this.newsList = res.page.list.map((item) => {
            let str = item.content
              .replace(/<[^<>]+>/g, "")
              .replace(/&nbsp;/gi, "");
            item.detail = str;
            item.src = this.formatImageUrl(JSON.parse(item.image)[0].raw);
            return item;
          });
        }
      });
    },
    currentChange(val) {
      this.pageNum = val;
      this.getNew();
    },
    detailsClick(id) {
      this.$router.push({ path: `/news-details/${id}` });
    },
    getCategory() {
      getCategory().then((res) => {
        if (res.code == 0) {
          this.optionList = res.infos;
          this.type = this.optionList[0].id;
          this.optionList.push({
            type: "视频",
            id: "",
          });
          this.getNew();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and(max-width: 900px) {
  .news-page {
    .bannar {
      width: 100%;
      // height: 5.5rem;
      background: #666;
      margin-top: 20px;
      position: relative;
      .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 9;
      }
      img {
        vertical-align: middle;
        height: 30vw;
        width: 100%;
      }
      .bannar-info {
        position: absolute;
        top: 20%;
        left: 0;
        width: 100%;
        text-align: center;
        padding: 0 20px;
        .bannar-title {
          font-size: 36px;
          color: #fff;
          margin-bottom: 20px;
        }
        .bannar-detail {
          font-size: 24px;
          color: #fff;
        }
      }
    }
    main {
      box-sizing: border-box;
      // width: 1200px;
      padding: 0.8rem 0rem;
      background-color: #fff;
      margin: 0 auto;
      .main-option {
        display: flex;
        justify-content: center;
        margin-bottom: 0.6rem;
        .main-option-item:nth-last-child(1) {
          margin-right: 0;
        }
        .main-option-item {
          width: 1.2rem;
          height: 0.5rem;
          background-color: #f1f1f1;
          font-size: 0.18rem;
          color: #666;
          text-align: center;
          line-height: 0.5rem;
          margin-right: 0.6rem;
          cursor: pointer;
          border-radius: 0.04rem;
        }
        .active {
          background-color: #ee6a23;
          color: #fff;
        }
      }
      .main-content {
        padding: 0 20px;
        box-sizing: border-box;
        .main-content-item:nth-last-child(1) {
          margin-bottom: 0;
        }
        .main-content-item {
          width: 100%;
          display: flex;
          margin-bottom: 0.4rem;
          cursor: pointer;
          .main-content-item-img {
            width: 50%;
            // height: 100%;
            flex-shrink: 0;
            img {
              width: 100%;
              height: 100%;
              // height: 2.1rem;
              background-color: #fff;
              display: block;
              // flex-shrink: 0;
            }
          }

          .info {
            box-sizing: border-box;
            padding: 0.3rem 0.4rem;
            border: 1px solid #e6e6e6;
            width: 50%;
            .title {
              font-size: 24px;
              color: #333;
              margin-bottom: 0.1rem;
              font-weight: bold;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .content {
              font-size: 18px;
              line-height: 2;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              overflow: hidden;
              color: #666;
              margin-bottom: 0.1rem;
              height: 110px;
            }
            .time {
              font-size: 18px;
              color: #666;
            }
          }
        }
      }
      .main-vedios {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 0 20px;

        .main-vedios-item {
          width: 100%;
          margin-bottom: 40px;
          position: relative;
          cursor: pointer;
          .vedios-img {
            img {
              height: 3.2rem;
              width: 100%;
              vertical-align: middle;
            }
          }
          .vedios-info {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding: 10px 20px;
            .title {
              font-size: 18px;
              flex: 1;
              margin-right: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .time {
              font-size: 12px;
            }
          }
        }
      }
      .pagination {
        margin-top: 40px;
        text-align: center;
      }
    }
  }
}
@media screen and(min-width: 900px) {
  .news-page {
    .bannar {
      width: 100%;
      // height: 5.5rem;
      background: #666;
      position: relative;
      img {
        vertical-align: middle;
        height: 550px;
        width: 100%;
      }
      .bannar-info {
        position: absolute;
        top: 40%;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 9;
        .bannar-title {
          font-size: 36px;
          color: #fff;
          margin-bottom: 20px;
        }
        .bannar-detail {
          font-size: 24px;
          color: #fff;
        }
      }
    }
    main {
      box-sizing: border-box;
      width: 1200px;
      padding: 0.8rem 0rem;
      background-color: #fff;
      margin: 0 auto;
      .main-option {
        display: flex;
        justify-content: center;
        margin-bottom: 0.6rem;
        .main-option-item:nth-last-child(1) {
          margin-right: 0;
        }
        .main-option-item {
          width: 1.8rem;
          height: 0.5rem;
          background-color: #f1f1f1;
          font-size: 0.18rem;
          color: #666;
          text-align: center;
          line-height: 0.5rem;
          margin-right: 0.6rem;
          cursor: pointer;
          border-radius: 0.04rem;
        }
        .active {
          background-color: #ee6a23;
          color: #fff;
        }
      }
      .main-content {
        .main-content-item:nth-last-child(1) {
          margin-bottom: 0;
        }
        .main-content-item:hover {
          .info {
            background-color: #f1f1f1;
          }
        }
        .main-content-item {
          width: 100%;
          display: flex;
          margin-bottom: 0.4rem;
          cursor: pointer;
          .main-content-item-img {
            width: 3.2rem;
            height: 2.1rem;
            display: block;
            flex-shrink: 0;
            overflow: hidden;
            img {
              width: 3.2rem;
              height: 2.1rem;
              display: block;
              transition: 0.5s all;
            }
            img:hover {
              transition: 0.5s all;
              transform: scale(1.1);
            }
          }

          .info {
            box-sizing: border-box;
            padding: 0.3rem 0.4rem;
            border: 1px solid #e6e6e6;
            .title {
              font-size: 0.18rem;
              color: #333;
              margin-bottom: 0.1rem;
              font-weight: bold;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .content {
              font-size: 0.12rem;
              line-height: 2;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              overflow: hidden;
              color: #666;
              margin-bottom: 0.1rem;
            }
            .time {
              font-size: 0.12rem;
              color: #666;
            }
          }
        }
      }
      .main-vedios {
        display: flex;
        flex-wrap: wrap;
        .main-vedios-item:nth-child(2n) {
          margin-right: 0;
        }
        .main-vedios-item {
          width: calc(50% - 20px);
          margin-right: 40px;
          margin-bottom: 40px;
          position: relative;
          cursor: pointer;
          .vedios-img {
            img {
              height: 3.2rem;
              width: 100%;
              vertical-align: middle;
            }
          }
          .vedios-info {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding: 10px 20px;
            .title {
              font-size: 18px;
              flex: 1;
              margin-right: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .time {
              font-size: 12px;
            }
          }
        }
      }
      .pagination {
        margin-top: 40px;
        text-align: center;
      }
    }
  }
}
.news-page {
  /deep/.pagination {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #ee6a23;
    }
    .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #ee6a23;
    }
  }
}
</style>